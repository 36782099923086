import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from "../../../components/shared/section-title";
import { TitleContainer, SectionWrapper, ServiceListItem } from './services.stc'
import mobileIcon from '../../../assets/img/icons/mobileIcon.png'
import softwareIcon from '../../../assets/img/icons/softwareIcon.png'
import webIcon from '../../../assets/img/icons/webIcon.png'

 const img = [softwareIcon, mobileIcon, webIcon]
const ServiceSection = () => {

  const homeServiceSectionData = useStaticQuery(graphql `
    query homeServiceSectionQuery {
      sanityHome {
        serviceHeadline
        serviceSubhead
        featuredServices {
          _id
          title
          subtitle
          slug {
            current
          }
          icon {
            icon {
              asset {
                url
              }
            }
            iconBackground {
              rgb {
                r
                g
                b
                a
              }
            }
            iconColor {
              rgb {
                r
                g
                b
                a
              }
            }
          }
        }
      }
    }
  `);

  const {serviceHeadline: headline, serviceSubhead: subhead} = homeServiceSectionData.sanityHome;
  const serviceList = homeServiceSectionData.sanityHome.featuredServices;

  return (
    <SectionWrapper>
      <TitleContainer>
        <SectionTitle title={headline} subtitle={subhead} />
      </TitleContainer>
        {serviceList.map((data, index) => {
          return(
            <ServiceListItem
              key={data._id}
              title={data.title}
              subtitle={data.subtitle}
              slug={data.slug.current}
              iconRaw={img[index]}
              color={data.icon.iconColor && data.icon.iconColor.rgb}
              bgcolor={data.icon.iconBackground && data.icon.iconBackground.rgb}
            />
          )
        })}
    </SectionWrapper>
  )
}
 
export default ServiceSection