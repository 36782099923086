import React, { useEffect } from 'react';
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout';
import Hero from '../../components/hero';
import Body from '../../components/body';
import CardList from '../../components/cardlist';
import DetailList from '../../components/detail/list';
import FeaturedProjects from '../../components/featuredprojects';
import Cta from '../../components/cta';
import { graphql } from 'gatsby'

// TODO Separate this into globla component
import ServiceSection from '../../containers/home/services';

export const serviceTemplateData = graphql`
  query serviceTemplateQuery($id: String!) {
    service: sanityService(id: { eq: $id }) {
      hero {
        title
        subtitle
        image {
          asset {
            fluid(maxWidth: 1920, maxHeight: 950) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
      }
      _rawMainContent
      mainContent {
        headline
        subhead
        image {
          asset {
            fluid(maxWidth: 1920, maxHeight: 950) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      serviceDetails {
        _key
        title
        summary
        icon {
          iconBackground {
            rgb {
              r
              g
              b
              a
            }
          }
          iconColor {
            rgb {
              r
              g
              b
              a
            }
          }
          icon {
            asset {
              url
            }
          }
        }
        feature {
          subtitle
          title
          image {
            asset {
              fluid(maxWidth: 1920, maxHeight: 750) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          cta {
            copy
            path {
              page {
                ... on SanityServices {
                  slug {
                    current
                  }
                }
                ... on SanityProjects {
                  slug {
                    current
                  }
                }
                ... on SanityOurProcess {
                  slug {
                    current
                  }
                }
                ... on SanityHome {
                  slug {
                    current
                  }
                }
                ... on SanityContact {
                  slug {
                    current
                  }
                }
                ... on SanityAbout {
                  slug {
                    current
                  }
                }
                ... on SanityProject {
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
      _rawServiceDetails
      relatedProjects {
        title
        slug {
          current
        }
        isFeatured
        _rawHomeSummary
        client
        category
        _rawImg
        _id
      }
      ctaTitle
      ctaActive
      ctaLink {
        copy
        path {
          page {
            ... on SanityServices {
              slug {
                current
              }
            }
            ... on SanityProjects {
              slug {
                current
              }
            }
            ... on SanityOurProcess {
              slug {
                current
              }
            }
            ... on SanityHome {
              slug {
                current
              }
            }
            ... on SanityContact {
              slug {
                current
              }
            }
            ... on SanityAbout {
              slug {
                current
              }
            }
            ... on SanityProject {
              slug {
                current
              }
            }
            ... on SanityService {
              slug {
                current
              }
            }
          }
        }
        copy
      }
      seo {
        meta_description
        seo_title
      }
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const ServiceTemplate = (props) => {
  const { data } = props;
  const service = data.service;
  const details = service.serviceDetails;
  const detailCopyData = service._rawServiceDetails;
  const currentPath = props.location.pathname;
  const projects = service.relatedProjects;
  const seoMeta = data.seo;
  const openGraph = data.openGraph;

  return (
    <Layout headerLayout={2}>
      <SEO
        title={seoMeta?.seo_title}
        ogtitle={openGraph?.title}
        description={seoMeta?.meta_description}
        ogdescription={openGraph?.description}
        image={openGraph?.image?.asset?.fluid}
        pathname={props.location.pathname}
      />
      <Hero
        title={service.hero.title}
        subhead={service.hero.subtitle}
        image={service.hero.image.asset.fluid}
        alt={service.hero.image.alt}
      />
      <Body
        title={service.mainContent.headline}
        subhead={service._rawMainContent.subhead}
        copy={service._rawMainContent.copy}
        image={service.mainContent.image.asset.fluid}
        titleAlign={'left'}
      />
      <CardList
        cards={service.serviceDetails}
        currentPath={currentPath}
        cardBasis={'33%'}
        cardPadding={'0 25px'}
      />
      <DetailList features={details} copyRef={detailCopyData} />
      <FeaturedProjects projects={projects} />
      <Cta
        title={service.ctaTitle}
        call={service.ctaLink.copy}
        action={service.ctaLink.path.page.slug.current}
        active={service.ctaActive}
      />
      <ServiceSection />
    </Layout>
  );
};

export default ServiceTemplate;
