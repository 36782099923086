import React from 'react';
import { Link } from 'gatsby';
import SectionTitle from '../shared/section-title'
import { ProjectTitleContainer, ProjectSectionWrap, ProjectCardWrap } from './project.stc';

const FeaturedProjects = ({ projects }) => {
 
  
  return (
    <ProjectSectionWrap>
      <ProjectTitleContainer>
        <SectionTitle title={'Related Projects'} />
      </ProjectTitleContainer>

      <ProjectCardWrap>
        {projects.map((data) => {
          
          return (
            <Link  key={data.slug.current} to={`/${data.slug.current}`}>
            <span>{data.title}</span>
           </Link>
           
          );
        })}
      </ProjectCardWrap>
    </ProjectSectionWrap>
  );
};

export default FeaturedProjects;
