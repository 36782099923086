import styled from 'styled-components';
import {device} from '../../theme'

export const ProjectSectionWrap = styled.section `
    background-color: ${props => props.bg};
    padding: 30px 0;
    @media ${device.medium}{
        padding: 0;
    }
    @media ${device.small}{
        /* padding-bottom: 10vw; */
    }
`;

export const ProjectTitleContainer = styled.header `
    display: inline-block;
    width: 100%;
    padding: 30px 0;
`;

export const ProjectCardWrap = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 20px;
padding: 0px 12.5%;
    span {
        color: #111;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 2px;
        text-align: center;
        margin-top: 1em;
      }
`;